import {HeightDataType} from './config';

const METER_TO_FEET_CONSTANT = 3.28;
const METER_TO_INCH_CONSTANT = 2.54;
const CONSTANT_NUMBER = 12;

/**
 * Function is converting meters to feet and inches.
 * @param meters
 * @return {[number, number]} [feet, inches]
 */
export const metricToImperial = (meters) => {
  let length = (100 * meters) / METER_TO_INCH_CONSTANT;
  let feetIn = Math.floor(Number(meters) * METER_TO_FEET_CONSTANT);
  let inchIn = Math.floor(length - CONSTANT_NUMBER * feetIn);
  return [feetIn, inchIn];
};

/**
 * Format inches into two digits with leading 0.
 * @param inch
 * @return {string|*}
 */
export const formatInches = (inch) => (inch < 10 ? `0${inch}` : `${inch}`);

/**
 * Function will covert meters to format "feet.inches".
 * !!! Function is not converting meters to feet, so 1 meter is formatted to "3.03" and not "3.28"
 *
 * @param heightM height in meters
 * @return {string}
 */
export const meterToFeetDotInchesFormatter = (heightM) => {
  let [feetIn, inchIn] = metricToImperial(heightM);
  return feetIn + '.' + formatInches(inchIn);
};

/**
 * Function will covert height into format 'feet.inches'.
 * Input depends on units (metrics/imperial units), so the result text
 * will be calculated from meters ot feet + inches.
 *
 * @param heightM
 * @param heightFT
 * @param heightIN
 * @param units
 * @return {string}
 */
export const formatToFeetDotInches = (heightM, heightFT, heightIN, units) => {
  if (units === HeightDataType.METER) {
    return meterToFeetDotInchesFormatter(heightM);
  }
  return heightFT + '.' + heightIN;
};

const FEET_TO_METER_CONSTANT = 0.0254;

export const convertFeetToMeter = (heightFT = 0, heightIN = 0) => {
  let v =
    Number(heightFT) * CONSTANT_NUMBER * FEET_TO_METER_CONSTANT +
    Number(heightIN) * FEET_TO_METER_CONSTANT;
  return String(v.toFixed(2));
};
