export const URL = {
  currentENV: "PRODUCTION",
  LOCAL: {
    login:
      'https://7k7mletvt5.execute-api.us-east-1.amazonaws.com/lifeplus/login',
    graphQl:
      'https://bzh3lnhdwndtdi4blmf6eycbxe.appsync-api.us-east-1.amazonaws.com/graphql',
    getApiKey:
      'https://7k7mletvt5.execute-api.us-east-1.amazonaws.com/lifeplus/appsynckey',
  },
  DEVELOPMENT: {
    login:
      'https://7k7mletvt5.execute-api.us-east-1.amazonaws.com/lifeplus/login',
    uploadProfilePic:
      'https://7k7mletvt5.execute-api.us-east-1.amazonaws.com/lifeplus/profilepicupload',
    masterData:
      'https://7k7mletvt5.execute-api.us-east-1.amazonaws.com/lifeplus/masterdata',
    getApiKey:
      'https://7k7mletvt5.execute-api.us-east-1.amazonaws.com/lifeplus/appsynckey',
    graphQl:
      'https://bzh3lnhdwndtdi4blmf6eycbxe.appsync-api.us-east-1.amazonaws.com/graphql',
    graphQlAdmin:
      'https://qjklcqap3zew5gokivaxvlynsy.appsync-api.us-east-1.amazonaws.com/graphql',
    profileUrl: 'https://lifeplusweb.s3.amazonaws.com/profilePictures/',
  },
  STAGING: {
    login:
      'https://nw9du03kjk.execute-api.ap-northeast-1.amazonaws.com/Staging/login',
    uploadProfilePic:
      'https://nw9du03kjk.execute-api.ap-northeast-1.amazonaws.com/Staging/profilepicupload',
    getApiKey:
      'https://nw9du03kjk.execute-api.ap-northeast-1.amazonaws.com/Staging/appsynckey',
    masterData:
      'https://nw9du03kjk.execute-api.ap-northeast-1.amazonaws.com/Staging/masterdata',
    graphQl:
      'https://qxpypvxeqzbsdeh6iiplggyzwe.appsync-api.ap-northeast-1.amazonaws.com/graphql',
    graphQlAdmin:
      'https://gi5yddqt55ed7kqev5xmxepaxy.appsync-api.ap-northeast-1.amazonaws.com/graphql',
    profileUrl: 'https://staging-admin.lifeplus.ai/profilePictures/',
  },
  PRODUCTION: {
    login: 'https://api.lifeplus.ai/login',
    uploadProfilePic: 'https://api.lifeplus.ai/profilepicupload',
    masterData: 'https://api.lifeplus.ai/masterdata',
    getApiKey: 'https://api.lifeplus.ai/appsynckey',
    graphQl: 'https://api-web.lifeplus.ai',
    graphQlAdmin: 'https://api-admin.lifeplus.ai',
    profileUrl: 'https://admin.lifeplus.ai/profilePictures/',
  },
};

export const headers = {
  onlyType: {'Content-Type': 'application/json', 'access-method': 'EUREKA_WEB'},
  corsType: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, OPTIONS',
    'access-method': 'EUREKA_WEB',
  },
};

export const getHeadersWithKey = (apikey) => {
  return {
    'Content-Type': 'application/json',
    'x-api-key': apikey,
    'access-method': 'EUREKA_WEB',
  };
};

export const getHeadersWithKeyAndToken = (apikey, token) => {
  return {
    'Content-Type': 'application/json',
    'x-api-key': apikey,
    Authorization: 'Bearer ' + token,
    'access-method': 'EUREKA_WEB',
  };
};

export const getHeadersWithToken = (token) => {
  return {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
    'access-method': 'EUREKA_WEB',
  };
};
